import React from "react";

export default () => {
  return (
    <div className="py-8 footer-bg">
      <footer className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4">
          <div>
            <a
              href="https://www.mandoxglobal.com/"
              className="logo flex flex-row items-center"
            >
              <img
                src="/images/full-logo-footer.png"
                width="220"
                className="cursor-pointer"
                alt="LEAD"
              />
            </a>
            <div className="uppercase text-white text-md mb-3 mt-4 text-left">
              Follow Us
            </div>
            <div className="flex flex-row items-center ">
              <a
                href="https://instagram.com/official_mandox"
                target="_blank"
                rel="noopener noreferrer"
                className="mr-4"
              >
                <img src="/images/sm-instagram.svg" alt="" width="20" />
              </a>
              <a
                href="https://twitter.com/officialmandox"
                target="_blank"
                rel="noopener noreferrer"
                className="mr-4"
              >
                <img src="/images/sm-twitter.svg" alt="" width="20" />
              </a>
              <a
                href="https://www.reddit.com/r/MandoX"
                target="_blank"
                rel="noopener noreferrer"
                className="mr-4"
              >
                <img src="/images/sm-reddit.svg" alt="" width="20" />
              </a>
                            
              <a
                href="https://t.me/officialmandox"
                target="_blank"
                rel="noopener noreferrer"
                className="mr-4"
              >
                <img src="/images/sm-telegram.svg" alt="" width="20" />
              </a>
              <a
                href="https://discord.gg/dmdHm4NFAb"
                target="_blank"
                rel="noopener noreferrer"
                className="mr-4"
              >
                <img src="/images/sm-discord.svg" alt="" width="20" />
              </a>
            </div>
          </div>
          {/* <div className="text-white leading-7 text-xs cursor-pointer">
            <div className="font-Montserrat-ExtraBold uppercase">Products</div>
            <div>Business Console</div>
            <div>Settlement Layer</div>
            <div>NFTs</div>
            <div>Mandox Pride</div>
          </div> */}
          <div className="text-white leading-7 text-xs cursor-pointer flex flex-col">
            <a
              href="https://www.mandoxglobal.com"
              className="font-Montserrat-ExtraBold uppercase"
            >
              About Us
            </a>
            {/* <div>Project</div> */}
            <a href="https://www.mandoxglobal.com/contact/">Contact Us</a>
            <a href="https://www.mandoxglobal.com/team/">Team</a>
            {/* <div>Ecosystem</div> */}
            <a href="https://www.dextools.io/app/ether/pair-explorer/0x4767cf9a9d96ae63b6541c302143c461add94635">The Token</a>
          </div>
          {/* <div className="text-white leading-7 text-xs cursor-pointer">
            <div className="font-Montserrat-ExtraBold uppercase">Solutions</div>
            <div>Grow Your Business with our Monetization</div>
            <div>Technology</div>
            <div>The PSP Solution</div>
            <div>Use Your Cryptocurrency</div>
          </div> */}
          <div className="text-white leading-7 text-xs cursor-pointer flex flex-col">
            <div className="font-Montserrat-ExtraBold uppercase">
              Useful Links
            </div>
            <a
              href="https://www.mandoxglobal.com/whitepaper.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Whitepaper
            </a>
            <a
              href="https://linktr.ee/mandox_official"
              target="_blank"
              rel="noopener noreferrer"
            >
              Link Tree
            </a>
            <a
              href="https://www.mandoxedu.com"
              target="_blank"
              rel="noopener noreferrer"
            >
             MandoxEDU 
            </a>
          </div>
        </div>
        <div className="footer-copyright text-center py-3 text-white">
        <div fluid>
          &copy; {new Date().getFullYear()} Copyright: <a href="#"> Mandox Token </a>
        </div>
      </div>
      </footer>
    </div>
  );
};
