import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";


const initWeb3 = () => {
  return new Promise(async (resolve, reject) => {
    if (window.ethereum) {
      const web3 = new Web3(window.ethereum);
      try {
        await window.ethereum.enable();
        resolve(web3);
      } catch (error) {
        reject(error);
      }
    } else if (window.web3) {
      const web3 = window.web3;
      console.log("Injected web3 detected.");
      resolve(web3);
    } else {
//  Create WalletConnect Provider 27e484dcd9e3efcfd25a83a78777cdf1
           const provider = new WalletConnectProvider({
            infuraId: "8ebf99c71be74a93bb0443730be97a7b",
            bridge: "https://derelay.rabby.io/"
          });
          await provider.enable();
    }
  });
};

export { initWeb3 };
